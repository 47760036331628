// src/components/UpstoxCallback.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function UpstoxCallback() {
  const navigate = useNavigate();
  const location = useLocation();
  const backendURL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

  useEffect(() => {
    // Get the code from URL parameters
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    if (code) {
      // Send the code to backend
      fetch(`${backendURL}/upstox/callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      })
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            // If this was opened in a popup, close it and refresh parent
            if (window.opener) {
              window.opener.postMessage({ type: 'UPSTOX_LOGIN_SUCCESS' }, '*');
              window.close();
            } else {
              // If not in popup, redirect to home
              navigate('/');
            }
          } else {
            console.error('Login failed:', data.error);
            navigate('/');
          }
        })
        .catch(error => {
          console.error('Error during callback:', error);
          navigate('/');
        });
    }
  }, [navigate, location]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Processing Login...</h2>
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white mx-auto"></div>
      </div>
    </div>
  );
}