// contexts/AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [brokerStatus, setBrokerStatus] = useState({
    upstox: false,
    neo: false
  });

  const login = (broker) => {
    setBrokerStatus(prev => ({
      ...prev,
      [broker]: true
    }));
  };

  const logout = (broker) => {
    setBrokerStatus(prev => ({
      ...prev,
      [broker]: false
    }));
  };

  return (
    <AuthContext.Provider value={{ brokerStatus, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};



// // Example component using the auth state
// const NavBar = () => {
//   const { brokerStatus } = useAuth();
  
//   return (
//     <nav>
//       <div>Upstox: {brokerStatus.upstox ? 'Connected' : 'Disconnected'}</div>
//       <div>Neo: {brokerStatus.neo ? 'Connected' : 'Disconnected'}</div>
//     </nav>
//   );
// };

// // Example login component
// const LoginPage = () => {
//   const { login, brokerStatus } = useAuth();
  
//   const handleUpstoxLogin = async () => {
//     // Your Upstox login logic here
//     try {
//       // await upstoxLogin();
//       login('upstox');
//     } catch (error) {
//       console.error('Login failed:', error);
//     }
//   };

//   return (
//     <div>
//       <button 
//         onClick={handleUpstoxLogin}
//         disabled={brokerStatus.upstox}
//       >
//         Login to Upstox
//       </button>
//     </div>
//   );
// };