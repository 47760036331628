// import React, { useState, useEffect } from "react";

// const backendURL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

// export default function NeoLogin() {
//   const [neoLoggedIn, setNeoLoggedIn] = useState(false);

//   const checkNeoStatus = async () => {
//     try {
//       const response = await fetch(`${backendURL}/neo/status`);
//       const data = await response.json();
//       console.log(" data  neo login = ", data);
//       setNeoLoggedIn(data.loggedIn);
//     } catch (error) {
//       console.error("Error checking Neo status:", error);
//     }
//   };

//   useEffect(() => {
//     const handleMessage = (event) => {
//       if (event.data.type === "NEO_LOGIN_SUCCESS") {
//         checkNeoStatus();
//       }
//     };
//     window.addEventListener("message", handleMessage);
//     checkNeoStatus();

//     return () => window.removeEventListener("message", handleMessage);
//   }, []);
//   const handleNeoLogin = () => {
//     const popup = window.open(
//       "",
//       "NeoLoginPopup",
//       "width=400,height=300,left=500,top=300"
//     );
  
//     popup.document.write(`
//       <html>
//       <head>
//         <title>Kotak Neo API Login</title>
//         <style>
//           body { font-family: Arial, sans-serif; text-align: center; padding: 20px; }
//           button, input { margin: 10px; padding: 10px; }
//         </style>
//       </head>
//       <body>
//         <h2>Kotak Neo API Login</h2>
//         <button id="sendOtp">Send OTP</button>
//         <div id="otpSection" style="display: none;">
//           <input type="text" id="otp" placeholder="Enter OTP" />
//           <button id="submitOtp">Submit OTP</button>
//         </div>
//         <script>
//           document.getElementById("sendOtp").addEventListener("click", function() {
//             const sendOtpButton = document.getElementById("sendOtp");
//             sendOtpButton.disabled = true; // Disable button after clicking
//             fetch("${backendURL}/neo/send-otp", { method: "POST" })
//               .then(response => response.json())
//               .then(data => {
//                 if (data.success) {
//                   document.getElementById("otpSection").style.display = "block";
//                   sendOtpButton.style.display = "none"; // Hide Send OTP button
//                 } else {
//                   alert("Failed to send OTP");
//                   sendOtpButton.disabled = false; // Re-enable button if failed
//                 }
//               });
//           });
  
//           document.getElementById("submitOtp").addEventListener("click", function() {
//             const otp = document.getElementById("otp").value;
//             fetch("${backendURL}/neo/submit-otp", {
//               method: "POST",
//               headers: { "Content-Type": "application/json" },
//               body: JSON.stringify({ otp })
//             })
//               .then(response => response.json())
//               .then(data => {
//                 if (data.success) {
//                   window.opener.postMessage({ type: 'NEO_LOGIN_SUCCESS' }, "*");
//                   window.close();
//                 } else {
//                   alert("OTP verification failed");
//                 }
//               });
//           });
//         </script>
//       </body>
//       </html>
//     `);
//   };
  

//   return (
//     <div className="mb-6 flex flex-col items-center">
//       <p className="mb-3 flex items-center gap-2">
//         Kotak Neo Login:{" "}
//         <span
//           className={`font-semibold ${
//             neoLoggedIn ? "text-green-400" : "text-red-400"
//           }`}
//         >
//           {neoLoggedIn ? "✅ Logged In" : "❌ Not Logged In"}
//         </span>
//       </p>

//       {!neoLoggedIn && (
//         <button
//           onClick={handleNeoLogin}
//           className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
//         >
//           Login to Kotak Neo
//         </button>
//       )}
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Button } from "@mui/material";

const backendURL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export default function NeoLogin() {
  const { brokerStatus, login, logout } = useAuth();

  const checkNeoStatus = async () => {
    try {
      const response = await fetch(`${backendURL}/neo/status`);
      const data = await response.json();
      console.log(" data neo login = ", data);
      
      // Update global auth state instead of local state
      if (data.loggedIn) {
        login('neo');
      } else {
        logout('neo');
      }
    } catch (error) {
      console.error("Error checking Neo status:", error);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "NEO_LOGIN_SUCCESS") {
        checkNeoStatus();
      }
    };
    window.addEventListener("message", handleMessage);
    checkNeoStatus();

    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleNeoLogin = () => {
    const popup = window.open(
      "",
      "NeoLoginPopup",
      "width=400,height=300,left=500,top=300"
    );
  
    popup.document.write(`
      <html>
      <head>
        <title>Kotak Neo API Login</title>
        <style>
          body { font-family: Arial, sans-serif; text-align: center; padding: 20px; }
          button, input { margin: 10px; padding: 10px; }
        </style>
      </head>
      <body>
        <h2>Kotak Neo API Login</h2>
        <button id="sendOtp">Send OTP</button>
        <div id="otpSection" style="display: none;">
          <input type="text" id="otp" placeholder="Enter OTP" />
          <button id="submitOtp">Submit OTP</button>
        </div>
        <script>
          document.getElementById("sendOtp").addEventListener("click", function() {
            const sendOtpButton = document.getElementById("sendOtp");
            sendOtpButton.disabled = true; // Disable button after clicking
            fetch("${backendURL}/neo/send-otp", { method: "POST" })
              .then(response => response.json())
              .then(data => {
                if (data.success) {
                  document.getElementById("otpSection").style.display = "block";
                  sendOtpButton.style.display = "none"; // Hide Send OTP button
                } else {
                  alert("Failed to send OTP");
                  sendOtpButton.disabled = false; // Re-enable button if failed
                }
              });
          });
  
          document.getElementById("submitOtp").addEventListener("click", function() {
            const otp = document.getElementById("otp").value;
            fetch("${backendURL}/neo/submit-otp", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ otp })
            })
              .then(response => response.json())
              .then(data => {
                if (data.success) {
                  window.opener.postMessage({ type: 'NEO_LOGIN_SUCCESS' }, "*");
                  window.close();
                } else {
                  alert("OTP verification failed");
                }
              });
          });
        </script>
      </body>
      </html>
    `);
  };

  return (
    <div className="mb-6 flex flex-col items-center">
      <p className="mb-3 flex items-center gap-2">
        Kotak Neo Login:{" "}
        <span
          className={`font-semibold ${
            brokerStatus.neo ? "text-green-400" : "text-red-400"
          }`}
        >
          {brokerStatus.neo ? "✅ Logged In" : "❌ Not Logged In"}
        </span>
      </p>

      {!brokerStatus.neo && (
        <Button
          onClick={handleNeoLogin}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
        >
          Login to Kotak Neo
        </Button>
      )}
    </div>
  );
}