import React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LandingPageOne() {
  const navigate = useNavigate();

  const options = [
    { label: "F&O Indian Markets", color: "#1e88e5", path: "/FOtrader" },
    { label: "Stock", color: "#43a047", path: "/IStock" },
    { label: "Forex", color: "#8e24aa", path: "/Forex" },
  ];

  return (
    <div
      style={{
        minHeight: "100vh",
        background: "linear-gradient(to bottom right, #1a1a2e, #16213e)",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* Navigation Bar */}
      <nav style={{ position: "absolute", top: 20, right: 40 }}>
        <Button color="inherit" style={{ marginRight: 10 }}>
          Insights
        </Button>
        <Button color="inherit">Performance</Button>
      </nav>

      {/* Main Heading */}
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        What would you like to trade today?
      </Typography>

      {/* Options Grid */}
      <Grid container spacing={3} justifyContent="center">
        {options.map((item, index) => (
          <Grid item key={index} xs={12} sm={4}>
            <Card
              style={{
                backgroundColor: item.color,
                color: "white",
                borderRadius: 12,
                textAlign: "center",
                padding: 20,
                boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
              }}
            >
              <CardContent>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => navigate(item.path)}
                  style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    color: "white",
                    fontSize: 16,
                    padding: "10px 0",
                  }}
                >
                  {item.label}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}