import React, { useState } from 'react';
import axios from 'axios';
import { Button, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const backendURL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

const ResetLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { brokerStatus, login, logout } = useAuth();

  const handleResetLogin = async () => {
    setLoading(true);
    setError('');

    try {
        const response = await axios.post(`${backendURL}/reset-login`);
        console.log("response from reset login = ", response);
        if (response.data.success) {
          // Reset both broker statuses in the auth context
          logout('upstox');
          logout('neo');
          setLoading(false);
        } else {
          setError('Failed to reset login state');
          setLoading(false);
        }
      } catch (err) {
        console.error("Reset login error:", err);
        setError('An error occurred while resetting login');
        setLoading(false);
      }
  };

  return (
    <div>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={handleResetLogin} 
        disabled={loading}
        startIcon={loading ? <CircularProgress size={20} /> : null}
      >
        {loading ? 'Resetting...' : 'Reset Login'}
      </Button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default ResetLogin;
