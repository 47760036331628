// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Container } from "@mui/material";
import TraderControl from "./components/TraderControl";

import UpstoxLogin from "./components/upstoxLogin_1_test.js";
import HomeRedirect from "./components/HomeRedirect";
import LandingPageOne from "./components/LandingPageOne";
import FOTrader from "./components/FOTrader";
import UpstoxCallback from "./components/UpstoxCallback.js";
import { AuthProvider } from "./contexts/AuthContext.js";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Container>
          <Routes>
            {/* Route to TraderControl Component */}
            {/* <Route path="/home" element={< HomeRedirect />} /> */}
            <Route path="/home" element={<TraderControl />} />
            <Route path="/" element={<LandingPageOne />} />
            <Route path="/upstox/callback" element={<UpstoxCallback />} />
            <Route path="/FOtrader" element={<FOTrader />} />
            {/* <Route path="/IStock" element={<IStock />} />
          <Route path="/Forex" element={<Forex />} /> */}

            {/* Future Route for PriceFeed or Other Components */}
            {/* <Route path="/price-feed" element={<PriceFeed />} /> */}

            {/* Add other routes as needed for future analytics or components */}
          </Routes>
        </Container>
      </Router>
    </AuthProvider>
  );
}

export default App;
