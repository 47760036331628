// import React, { useState, useEffect } from "react";
// import { useAuth } from "../contexts/AuthContext";


// const backendURL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

// export default function UpstoxLogin() {
//   const [upstoxLoggedIn, setUpstoxLoggedIn] = useState(false);
//   const [isLoggingIn, setIsLoggingIn] = useState(false);

//   const { brokerStatus, login, logout } = useAuth();

//   const checkUpstoxStatus = async () => {
//     try {
//       const response = await fetch(`${backendURL}/upstox/status`);
//       const data = await response.json();
//       setUpstoxLoggedIn(data.loggedIn);
//       setIsLoggingIn(false);
//     } catch (error) {
//       console.error("Error checking Upstox status:", error);
//       setIsLoggingIn(false);
//     }
//   };

//   useEffect(() => {
//     const handleMessage = (event) => {
//       if (event.data.type === "UPSTOX_LOGIN_SUCCESS") {
//         checkUpstoxStatus();
//       }
//     };
//     window.addEventListener("message", handleMessage);
//     checkUpstoxStatus();

//     return () => window.removeEventListener("message", handleMessage);
//   }, []);

//   const handleUpstoxLogin = () => {
//     setIsLoggingIn(true);
//     const width = 900;
//     const height = 700;
//     const left = (window.screen.width - width) / 2;
//     const top = (window.screen.height - height) / 2;

//     const loginPopup = window.open(
//       `${backendURL}/upstox/login`,
//       "UpstoxLogin",
//       `width=${width},height=${height},left=${left},top=${top}`
//     );

//     const popupCheck = setInterval(() => {
//       if (loginPopup.closed) {
//         clearInterval(popupCheck);
//         checkUpstoxStatus();
//       }
//     }, 1000);
//   };

//   return (
//     <div className="mb-6 flex flex-col items-center">
//       <p className="mb-3 flex items-center gap-2">
//         Upstox Login:{" "}
//         <span
//           className={`font-semibold ${
//             upstoxLoggedIn ? "text-green-400" : "text-red-400"
//           }`}
//         >
//           {upstoxLoggedIn ? "✅ Logged In" : "❌ Not Logged In"}
//         </span>
//       </p>

//       {!upstoxLoggedIn && (
//         <button
//           onClick={handleUpstoxLogin}
//           disabled={isLoggingIn}
//           className={`px-4 py-2 bg-green-500 text-white rounded transition-all ${
//             isLoggingIn ? "opacity-50 cursor-not-allowed" : "hover:bg-green-600"
//           }`}
//         >
//           {isLoggingIn ? "Logging in..." : "Login to Upstox"}
//         </button>
//       )}
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Button } from "@mui/material";

const backendURL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export default function UpstoxLogin() {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { brokerStatus, login, logout } = useAuth();
  
  const checkUpstoxStatus = async () => {
    try {
      const response = await fetch(`${backendURL}/upstox/status`);
      const data = await response.json();
      
      // Update the global auth state instead of local state
      if (data.loggedIn) {
        login('upstox');
      } else {
        logout('upstox');
      }
      
      setIsLoggingIn(false);
    } catch (error) {
      console.error("Error checking Upstox status:", error);
      setIsLoggingIn(false);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "UPSTOX_LOGIN_SUCCESS") {
        checkUpstoxStatus();
      }
    };

    window.addEventListener("message", handleMessage);
    checkUpstoxStatus(); // Check status on component mount

    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleUpstoxLogin = () => {
    setIsLoggingIn(true);
    const width = 900;
    const height = 700;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const loginPopup = window.open(
      `${backendURL}/upstox/login`,
      "UpstoxLogin",
      `width=${width},height=${height},left=${left},top=${top}`
    );

    const popupCheck = setInterval(() => {
      if (loginPopup.closed) {
        clearInterval(popupCheck);
        checkUpstoxStatus();
      }
    }, 1000);
  };

  return (
    <div className="mb-6 flex flex-col items-center">
      <p className="mb-3 flex items-center gap-2">
        Upstox Login:{" "}
        <span
          className={`font-semibold ${
            brokerStatus.upstox ? "text-green-400" : "text-red-400"
          }`}
        >
          {brokerStatus.upstox ? "✅ Logged In" : "❌ Not Logged In"}
        </span>
      </p>

      {!brokerStatus.upstox && (
        <Button
          onClick={handleUpstoxLogin}
          disabled={isLoggingIn}
          className={`px-4 py-2 bg-green-500 text-white rounded transition-all ${
            isLoggingIn ? "opacity-50 cursor-not-allowed" : "hover:bg-green-600"
          }`}
        >
          {isLoggingIn ? "Logging in..." : "Login to Upstox"}
        </Button>

      )}
    </div>
  );
}