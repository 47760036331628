// import React, { useState, useEffect } from "react";

// const backendURL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

// export default function FOTrader() {
//   const [traderStatus, setTraderStatus] = useState(null);
//   const [upstoxLoggedIn, setUpstoxLoggedIn] = useState(false);
//   const [neoLoggedIn, setNeoLoggedIn] = useState(false);
//   const [isLoggingIn, setIsLoggingIn] = useState(false);

//   const checkUpstoxStatus = async () => {
//     try {
//       const response = await fetch(`${backendURL}/upstox/status`);
//       const data = await response.json();
//       console.log(" data = ",data);
//       setUpstoxLoggedIn(data.loggedIn);
//       setIsLoggingIn(false);
//     } catch (error) {
//       console.error("Error checking Upstox status:", error);
//       setIsLoggingIn(false);
//     }
//   };
//   const checkNeoStatus = async () => {
//     try {
//       const response = await fetch(`${backendURL}/neo/status`);
//       const data = await response.json();
//       setNeoLoggedIn(data.loggedIn);
//     } catch (error) {
//       console.error("Error checking Neo status:", error);
//     }
//   };

//   useEffect(() => {
//     // Add message listener for popup communication
//     const handleMessage = (event) => {
//       if (event.data.type === 'UPSTOX_LOGIN_SUCCESS') {
//         checkUpstoxStatus();
//       }
//     };
//     window.addEventListener('message', handleMessage);

//     // Initial status checks
//     fetch(`${backendURL}/trader/status`)
//       .then((res) => res.json())
//       .then((data) => setTraderStatus(data.status));

//     checkUpstoxStatus();

//     fetch(`${backendURL}/neo/status`)
//       .then((res) => res.json())
//       .then((data) => setNeoLoggedIn(data.loggedIn));

//     // Cleanup
//     return () => window.removeEventListener('message', handleMessage);
//   }, []);

//   const handleTraderAction = () => {
//     const action = traderStatus === "running" ? "stop" : "start";
//     fetch(`${backendURL}/trader/${action}`, { method: "POST" })
//       .then((res) => res.json())
//       .then(() => setTraderStatus(action === "start" ? "running" : "stopped"));
//   };

//   const handleUpstoxLogin = () => {
//     setIsLoggingIn(true);
//     const width = 900;
//     const height = 700;
//     const left = (window.screen.width - width) / 2;
//     const top = (window.screen.height - height) / 2;

//     const loginPopup = window.open(
//       `${backendURL}/upstox/login`,
//       'UpstoxLogin',
//       `width=${width},height=${height},left=${left},top=${top}`
//     );

//     // Check if popup was closed manually
//     const popupCheck = setInterval(() => {
//       if (loginPopup.closed) {
//         clearInterval(popupCheck);
//         checkUpstoxStatus(); // Check status when popup closes
//       }
//     }, 1000);
//   };

//   const handleNeoLogin = () => {
//     const popup = window.open(
//       "",
//       "NeoLoginPopup",
//       "width=400,height=300,left=500,top=300"
//     );

//     popup.document.write(`
//       <html>
//       <head>
//         <title>Kotak Neo API Login</title>
//         <style>
//           body { font-family: Arial, sans-serif; text-align: center; padding: 20px; }
//           button, input { margin: 10px; padding: 10px; }
//         </style>
//       </head>
//       <body>
//         <h2>Kotak Neo API Login</h2>
//         <button id="sendOtp">Send OTP</button>
//         <div id="otpSection" style="display: none;">
//           <input type="text" id="otp" placeholder="Enter OTP" />
//           <button id="submitOtp">Submit OTP</button>
//         </div>
//         <script>
//           document.getElementById("sendOtp").addEventListener("click", function() {
//             fetch("${backendURL}/neo/send-otp", { method: "POST" })
//               .then(response => response.json())
//               .then(data => {
//                 if (data.success) {
//                   document.getElementById("otpSection").style.display = "block";
//                 } else {
//                   alert("Failed to send OTP");
//                 }
//               });
//           });

//           document.getElementById("submitOtp").addEventListener("click", function() {
//             const otp = document.getElementById("otp").value;
//             fetch("${backendURL}/neo/submit-otp", {
//               method: "POST",
//               headers: { "Content-Type": "application/json" },
//               body: JSON.stringify({ otp })
//             })
//               .then(response => response.json())
//               .then(data => {
//                 if (data.success) {
//                   window.opener.postMessage({ type: 'NEO_LOGIN_SUCCESS' }, "*");
//                   window.close();
//                 } else {
//                   alert("OTP verification failed");
//                 }
//               });
//           });
//         </script>
//       </body>
//       </html>
//     `);
//   };

//   return (
//     <div className="min-h-screen flex flex-col items-center justify-center text-white bg-gray-900">
//       <h1 className="text-3xl font-bold mb-6">F&O Indian Markets</h1>

//       <p className="text-lg mb-4">
//         Trader Status: {traderStatus || "Loading..."}
//       </p>

//       <button
//         onClick={handleTraderAction}
//         className="px-6 py-2 bg-blue-500 text-white rounded mb-6 hover:bg-blue-600 transition-colors"
//       >
//         {traderStatus === "running" ? "Stop Trader" : "Start Trader"}
//       </button>

//       <div className="mb-6 flex flex-col items-center">
//         <p className="mb-3 flex items-center gap-2">
//           Upstox Login: {" "}
//           <span className={`font-semibold ${upstoxLoggedIn ? 'text-green-400' : 'text-red-400'}`}>
//             {upstoxLoggedIn ? "✅ Logged In" : "❌ Not Logged In"}
//           </span>
//         </p>

//         {!upstoxLoggedIn && (
//           <button
//             onClick={handleUpstoxLogin}
//             disabled={isLoggingIn}
//             className={`
//               px-4 py-2
//               bg-green-500
//               text-white
//               rounded
//               transition-all
//               ${isLoggingIn
//                 ? 'opacity-50 cursor-not-allowed'
//                 : 'hover:bg-green-600'
//               }
//             `}
//           >
//             {isLoggingIn ? "Logging in..." : "Login to Upstox"}
//           </button>
//         )}
//       </div>

//       <div>
//         <p className="mb-3 flex items-center gap-2">
//           Kotak Neo Login: {" "}
//           <span className={`font-semibold ${neoLoggedIn ? 'text-green-400' : 'text-red-400'}`}>
//             {neoLoggedIn ? "✅ Logged In" : "❌ Not Logged In"}
//           </span>
//         </p>

//         {!neoLoggedIn && (
//           <button
//             onClick={handleNeoLogin}
//             className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
//           >
//             Login to Kotak Neo
//           </button>
//         )}
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import UpstoxLogin from "./UpstoxLogin";
import NeoLogin from "./NeoLogin";
import ResetLogin from "./ResetLogin";
import { useAuth } from "../contexts/AuthContext";
import { Button, Box } from "@mui/material";

const backendURL = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

export default function FOTrader() {
  const [traderStatus, setTraderStatus] = useState(null);
  const { brokerStatus, login, logout } = useAuth();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${backendURL}/trader/status`)
      .then((res) => res.json())
      .then((data) => setTraderStatus(data.status));
  }, []);

  const handleTraderAction = (action) => {
    setLoading(true);

    fetch(`${backendURL}/trader/${action}`, { method: "POST" })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(() => setTraderStatus(action === "start" ? "running" : "stopped"))
      .catch((error) => console.error(`Error ${action}ing trader:`, error))
      .finally(() => setLoading(false));
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center text-white bg-gray-900">
      <h1 className="text-3xl font-bold mb-6">F&O Indian Markets</h1>

      <p className="text-lg mb-4">Trader Status: {traderStatus || "stopped"}</p>

      <Box display="flex" alignItems="center">
        {/* Start Trader Button */}
        <Button
          variant="contained"
          color="success"
          onClick={() => handleTraderAction("start")}
          disabled={traderStatus === "running" || loading}
        >
          Start Trader
        </Button>

        {/* Stop Trader Button */}
        <Button
          variant="contained"
          color="error"
          onClick={() => handleTraderAction("stop")}
          disabled={traderStatus === "stopped" || loading}
          sx={{ ml: 2 }} // Adds left margin for spacing
        >
          Stop Trader
        </Button>

        {/* Reset Login Button (More Separated) */}
        <Box sx={{ ml: 15 }}>
          {" "}
          {/* Adds extra spacing */}
          <ResetLogin />
        </Box>
      </Box>

      <UpstoxLogin />
      <NeoLogin />
    </div>
  );
}
